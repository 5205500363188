import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Topbar from "@common/Topbar";
import Navbar from "@common/Navbar";
import { useWindowScroll } from "react-use";

const InnerWrapper = styled.div`
  background: white;
  z-index: 1100;
  @media (min-width: 992px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: ease-in-out 0.2s;
    ${(props) => (props.blah ? `transform: translateY(-125px)` : "")}
  }
`;

const Wrapper = styled.div`
  min-height: 122px;
  overflow: hidden;
  background: white;
`;

export const TopHeader = ({ data }) => {
  const [sticky, setSticky] = useState(false);
  const [lastY, setLastY] = useState(0);
  const { x, y } = useWindowScroll();
  if (y !== lastY) {
    if (y < 140) {
      if (sticky) {
        setSticky(false);
      }
    }
    if (y >= 140) {
      if (y <= lastY) {
        if (sticky) {
          setSticky(false);
        }
      } else {
        if (!sticky) {
          setSticky(true);
        }
      }
    }
    setLastY(y);
  }
  return (
    <Wrapper>
      <InnerWrapper blah={sticky}>
        <Topbar />
        <Navbar />
      </InnerWrapper>
    </Wrapper>
  );
};
