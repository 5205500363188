import React, { Component } from "react";

import { Container } from "@components/global";
import {
  Nav,
  NavItem,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  Brand,
} from "./style";

import { ReactComponent as MenuIcon } from "@static/icons/menu.svg";
import { Link } from "gatsby";

const NAV_ITEMS = [
  {
    name: "Rezerwacja",
    href: "/",
    children: [
      {
        name: "Wypożyczalnia samochodów Warszawa",
        href: "/",
      },
      {
        name: "Wypożyczalnia samochodów Gdańsk",
        href: "/wypozyczalnia-samochodow-gdansk",
      },
    ],
  },
  {
    name: "Samochody",
    href: "/samochody",
  },
  {
    name: "Oferta",
    href: "/wynajem-dlugoterminowy-samochodow",
  },
  {
    name: "Aktualności",
    href: "/aktualnosci",
  },
  {
    name: "FAQ",
    href: "/faq",
  },
  {
    name: "Kontakt",
    href: "/kontakt",
  },
];

class Navbar extends Component {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuOpen: !prevState.mobileMenuOpen,
    }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  getNavLink = (item) => (
    <Link to={item.href} activeClassName="active">
      {item.name}
    </Link>
  );

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      {NAV_ITEMS.map((navItem) => (
        <NavItem key={navItem.href}>
          {this.getNavLink(navItem)}
          {navItem.children ? (
            <ul>
              {navItem.children.map((c) => (
                <NavItem key={c.href}>{this.getNavLink(c)}</NavItem>
              ))}
            </ul>
          ) : null}
        </NavItem>
      ))}
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen } = this.state;

    return (
      <Nav {...this.props}>
        <StyledContainer>
          <Mobile>
            <button onClick={this.toggleMobileMenu} style={{ color: "white" }}>
              <MenuIcon />
            </button>
            <span>Menu</span>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    );
  }
}

export default Navbar;
