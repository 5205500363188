import React from "react";
import styled from "styled-components";
import { Container } from "@components/global";
import ExternalLink from "@common/ExternalLink";
import { Email } from "@common/Email";
import { Phone } from "styled-icons/remix-fill";
import { Link } from "gatsby";
import Map from "@sections/Map";
import { LogoImage } from "@components/global";
import { Email as IconEmail } from "styled-icons/material";
import { PhoneIcon } from "@common/PhoneIcon";

import { FacebookSquare } from "styled-icons/boxicons-logos";

const SOCIAL = [
  {
    Icon: FacebookSquare,
    link: "aaa",
  },
];

const Linki = styled.ul`
  color: #222;
  font-weight: 700;
  line-height: 1.5;
  list-style: none;
  padding-left: 0;

  a {
    color: white;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    text-decoration: underline;
  }
`;

const NAV_ITEMS = [
  {
    name: "Rezerwacja",
    href: "/",
  },
  {
    name: "Wynajem samochodów Gdańsk",
    href: "/wypozyczalnia-samochodow-gdansk",
  },
  {
    name: "Samochody",
    href: "/samochody",
  },
  {
    name: "Wynajem długoterminowy samochodów",
    href: "/wynajem-dlugoterminowy-samochodow",
  },
  {
    name: "Aktualności",
    href: "/aktualnosci",
  },
  {
    name: "FAQ",
    href: "/faq",
  },
  {
    name: "Kontakt",
    href: "/kontakt",
  },
  {
    name: "Polityka prywatności",
    href: "/polityka-prywatnosci",
  },
];

const Footer = () => (
  <>
    {/* <PhoneIcon /> */}
    <FooterWrapper>
      <Container fluid>
        <Grid>
          <MainFooter>
            <LogoSection>
              <Logo>
                <Link to="/">
                  <LogoImage />
                </Link>
              </Logo>
            </LogoSection>
            <Address>
              <Emails>
                <strong>Biuro obsługi klienta:</strong>
                <br />
                <IconEmail /> <Email />
                <br />
                <Phone />
                <a href="tel:+48883154531">+48 883-154-531</a>
              </Emails>
              RENT ME CAR
              <br />
              Rogalskiego 10
              <br />
              03-982 Warszawa
              {/* <br />
              Adam Manikowski
              <br />
              Dworszowice Kościelne Kolonia 31
              <br />
              98-331 Nowa Brzeźnica
              <br />
              NIP:508-006-78-29
              <br />
              REGON: 369425363 */}
            </Address>
          </MainFooter>
          <MapkaFooter>
            <Map />
          </MapkaFooter>
          <LinkiFooter>
            <SocialIcons>
              {SOCIAL.map(({ Icon, link }) => (
                <ExternalLink href={link} key={link}>
                  <Icon />
                </ExternalLink>
              ))}
            </SocialIcons>
            <Linki>
              {NAV_ITEMS.map((item) => (
                <li key={item.name}>
                  <a href={item.href}>{item.name}</a>
                </li>
              ))}
            </Linki>
          </LinkiFooter>
        </Grid>
      </Container>
    </FooterWrapper>

    <Copyright>
      <Container>
        Rent Me Car © {new Date().getFullYear()}. All rights reserved.
      </Container>
    </Copyright>

    <script
      dangerouslySetInnerHTML={{
        __html: `window.smartlook||(function(d) {
        var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
        var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
        c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);
        })(document);
        smartlook('init', 'dee75b6d50bc1b1124c2642c33bdda736986e7a1');`,
      }}
    />

    <script
      dangerouslySetInnerHTML={{
        __html: `
        var __cp = {"id":"fHt8d-acsumh_gCYaXY6Ki0iYp6aGcqMvGoKG-HzvgU","version":"1.1"};(function (window, document) {var cp = document.createElement('script');cp.type = 'text/javascript';cp.async = false;cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.');var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(cp, s);if (window.callpage) {alert('You could have only 1 CallPage code on your website!');} else {/*process the queue of calls*/window.callpage = function (method) {if (method == '__getQueue') {return this.methods;}else if (method) {if (typeof window.callpage.execute === 'function') {return window.callpage.execute.apply(this, arguments);}else {(this.methods = this.methods || []).push({arguments: arguments});}}};window.callpage.__cp = __cp;/*here comes execution*/window.callpage('api.button.autoshow');}})(window, document);
        
        `,
      }}
    />
  </>
);

const MapkaFooter = styled.div`
  & iframe {
    border-radius: 1rem;
    box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.08);
    border: solid 3px white;
  }
`;

const Address = styled.div``;
const Emails = styled.div`
  align-self: center;
  justify-self: center;
  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: unset;
    text-decoration: none;
  }
  a:hover,
  a:active,
  a:focus {
    color: ${(props) => props.theme.color.white.dark};
  }
  svg {
    width: 1em;
    height: 1em;
  }
`;

const Logo = styled.div`
  background: white;
  border-radius: 0.2rem;
  padding: 6px;
  align-self: center;
  max-width: 300px;
  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: unset;
    text-decoration: none;
  }
`;

const LogoSection = styled.div`
  margin-bottom: 1.5rem;
`;

const SocialIcons = styled.div`
  display: flex;
  margin-left: -8px;

  svg {
    width: 64px;
    height: 64px;
    color: ${(props) => props.theme.color.white.regular};
  }

  svg:hover,
  svg:focus,
  svg:active {
    color: ${(props) => props.theme.color.white.dark};
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.primary.regular};
  padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.xsmall};
  padding: 10px 15px;
  text-align: center;
  background: ${(props) => props.theme.color.black.regular};
  color: ${(props) => props.theme.color.white.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const MainFooter = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.small};
  color: white;
  @media (min-width: 720px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const LinkiFooter = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.small};
  color: white;
  @media (min-width: 720px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "mapka" "main" "linki";
  grid-gap: 1rem;
  @media (min-width: 991px) {
    grid-template-columns: 300px 1fr 270px;
    grid-template-areas: "main mapka linki";
  }
  @media (min-width: 1090px) {
    grid-template-columns: 300px 1fr 320px;
    grid-template-areas: "main mapka linki";
    grid-gap: 1.5rem;
  }
  ${MainFooter} {
    grid-area: main;
  }
  ${MapkaFooter} {
    grid-area: mapka;
  }
  ${LinkiFooter} {
    grid-area: linki;
  }
`;

export default Footer;
