import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

const Map = ({ ...rest }) => {
  const ref = useRef();
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.src =
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1631.0763525925017!2d21.077810862460602!3d52.22788467827058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecd9f0947803d%3A0x35c5a4dc4e06e822!2sStanis%C5%82awa%20Rogalskiego%2010%2C%2003-982%20Warszawa!5e0!3m2!1sen!2spl!4v1608388492359!5m2!1sen!2spl";
    }
  }, [ref]);
  return <Wrapper {...rest} ref={ref}></Wrapper>;
};

const Wrapper = styled.iframe`
  width: 100%;
  height: ${(props) => props["data-height"] || "350px"};
  border: none;

  @media (min-width: ${(props) => props.theme.screen.md}) {
    /* iframe {
      /* height: 500px; */
    /* } */
  }
`;

export default Map;
