import React, { useState } from "react";
import styled from "styled-components";
import { Phone } from "styled-icons/remix-fill";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Wrapper = styled.button`
  display: none;
  z-index: 120;
  @media (min-width: 500px) {
    display: block;
    position: fixed;
    bottom: 35px;
    right: 35px;
    width: 85px;
    height: 85px;
    background: ${(props) => props.theme.color.secondary.regular};
    border-radius: 50%;
    box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.08);
    color: white;
    fill: white;
    border: none;

    &:hover,
    &:focus {
      background: ${(props) => props.theme.color.secondary.light};
    }

    svg {
      width: 48px;
      height: 48px;
    }
  }
`;

const Dymek = styled.div`
  display: none;
  z-index: 100;
  @media (min-width: 500px) {
    display: block;
    position: fixed;
    bottom: 70px;
    right: 70px;
    width: 150px;
    height: 170px;
    background: white;
    border-radius: 0.25rem;
    color: #222;
    text-align: center;
    border: solid 1.5px #e5e5e5;
    padding: 5px;
    padding-top: 20px;
    box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.08);
    button {
      position: absolute;
      top: -10px;
      right: -16px;
      width: 24px;
      height: 24px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.08);
      border: solid 1px #e5e5e5;
      background: white;
      z-index: 110;
      line-height: 19px;
      &:hover,
      &:focus {
        background: #eee;
      }
    }
  }
`;

export const PhoneIcon = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [dymek, setDymek] = useState(
    parseInt(
      (typeof localStorage !== "undefined"
        ? localStorage.getItem("dymek")
        : "0") || "0"
    ) <
      Date.now() - 60 * 60 * 24 * 1000
  );
  const fullScreen = useMediaQuery("@media (max-width: 600px)");
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Wrapper
        onClick={() => {
          setOpen(true);
        }}
      >
        <Phone />
      </Wrapper>
      {dymek ? (
        <Dymek>
          Witaj!
          <br />
          Zadzwoń do nas już teraz i zarezerwuj samochód!
          <button
            onClick={() => {
              setDymek(false);
              if (typeof localStorage !== "undefined") {
                localStorage.setItem("dymek", Date.now());
              }
            }}
          >
            x
          </button>
        </Dymek>
      ) : null}
      {open ? (
        <Dialog
          fullScreen={false}
          open={true}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          aria-labelledby="responsive-dialog-title"
        >
          <div
            style={{
              padding: "50px 15px",
            }}
          >
            <p style={{ textAlign: "center", fontSize: "1.5rem" }}>
              Zadzwoń pod poniższy numer:
            </p>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "1.5rem",
                fontSize: "2.5rem",
              }}
            >
              <Phone
                style={{
                  width: "48px",
                  height: "48px",
                }}
              />
              <a href="tel:+48883154531">+48 883-154-531</a>
            </p>
          </div>
        </Dialog>
      ) : null}
    </>
  );
};
