import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Wrapper = styled.div`
  display: inline-block;
  a:last-child {
    display: none;
  }

  @media (min-width: 500px) {
    a:first-child {
      display: none;
    }
    a:last-child {
      display: inline-block;
    }
  }
`;

export const Email = ({ data }) => (
  <Wrapper>
    <a href="mailto:rentmecar24@gmail.com">rentmecar24@gmail.com</a>
    <Link to="/kontakt">rentmecar24@gmail.com</Link>
  </Wrapper>
);
