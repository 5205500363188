import styled from "styled-components";

import { Container } from "@components/global";

export const OuterWrapper = styled.div``;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.regular};
  padding: 5px 0;

  @media (min-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 15px 0;
  }
`;

export const Logo = styled.div`
  align-self: center;
  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: unset;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  svg {
    height: 40px;
    margin: 0 auto;
  }
`;

export const Contact = styled.div`
  align-self: center;
  justify-self: center;
  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: unset;
    text-decoration: none;
    font-size: ${(props) => props.theme.font_size.larger};
    font-weight: bold;
  }
  a:hover,
  a:active,
  a:focus {
    color: ${(props) => props.theme.color.primary.regular};
  }
  svg {
    width: 2.5rem;
    height: 2.5rem;
    color: #f68b1f;
    fill: #f68b1f;
    margin-right: 1rem;
  }
`;

export const CallBack = styled.div`
  align-self: center;
  justify-self: center;
`;

export const StyledContainer = styled(Container)``;
