import React from "react";
import { Email } from "@common/Email";
import { Email as IconEmail } from "styled-icons/material";
import { Phone } from "styled-icons/remix-fill";
import { Link } from "gatsby";
import {
  StyledContainer,
  OuterWrapper,
  Wrapper,
  Logo,
  Contact,
  CallBack,
} from "./style";
import { LogoImage } from "@components/global";

const Topbar = () => {
  return (
    <OuterWrapper>
      <StyledContainer>
        <Wrapper>
          <Logo>
            <Link to="/">
              <LogoImage />
            </Link>
          </Logo>
          <Contact>
            <IconEmail /> <Email />
          </Contact>
          <Contact>
            <Phone />
            <a href="tel:+48883154531">+48 883-154-531</a>
          </Contact>
        </Wrapper>
      </StyledContainer>
    </OuterWrapper>
  );
};
export default Topbar;
