import styled from "styled-components";

import { Container } from "@components/global";

export const Nav = styled.nav`
  background-color: ${(props) => props.theme.color.primary.regular};

  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding: 16px 0;
  }

  ${({ sticky }) =>
    sticky
      ? `
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1060;
  `
      : ""}
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavListWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  ${({ mobile }) =>
    mobile
      ? `
        flex-direction: column;
        margin-top: 0.75rem;

        > ${NavItem} {
          margin: 0;
          margin-top: 0;
          > ul {
            display: block;
          position: initial;
          margin-top: 0;
          }
        }
      `
      : `
      
      > ${NavItem} {
        > ul {
        }
      }
      `};
`;

export const NavItem = styled.li`
  font-family: ${(props) => props.theme.font.secondary};
  line-height: initial;
  background-color: ${(props) => props.theme.color.primary.regular};

  a {
    padding: 16px 0.75em;
    text-decoration: none;
    color: white;
    display: block;
  }

  position: relative;

  > ul {
    display: none;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1060;
    margin-left: 0;
    padding-left: 0;
    width: 100%;
    > li a {
      background-color: ${(props) =>
        props.theme.color.primary.regular} !important;
    }
    > li a:hover,
    > li a:focus,
    > li a:active {
      background: ${(props) => props.theme.color.primary.light} !important;
    }
  }

  @media (min-width: 992px) {
    > ul {
      width: 340px;
    }
  }

  &:hover > ul,
  &:focus > ul,
  &:active > ul {
    display: block;
  }

  a:hover,
  a:focus,
  a:active {
    background: ${(props) => props.theme.color.primary.light};
  }

  @media (min-width: 991px) {
    a[aria-current="page"] {
      background: ${(props) => props.theme.color.primary.light} !important;
      color: white;
    }
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    a[aria-current="page"] {
      border-radius: 5px;
    }
  }
`;

export const MobileMenu = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.primary.regular};
`;

export const Brand = styled.span`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.regular};
`;

export const Mobile = styled.div`
  display: none;
  font-family: ${(props) => props.theme.font.secondary};
  font-size: 1.1rem;
  color: white;

  span {
    margin-left: 15px;
    margin-top: -5px;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    display: flex;
    align-items: center;
    width: 100%;
  }

  ${(props) =>
    props.hide &&
    `
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: ${props.theme.screen.md}) {
      display: none;
    }
  `}
`;
